const header = document.querySelector('#header');
const burgerToggle = document.querySelector('#burger-toggle');
const menu = document.querySelector('#menu');

const hideHeader = () => {
  header.setAttribute('aria-hidden', 'true');
  burgerToggle.setAttribute('aria-expanded', 'false');
  menu.setAttribute('data-open', 'false');
};

const showHeader = () => {
  header.setAttribute('aria-hidden', 'false');
};

const toggleMenu = (state) => {
  burgerToggle.setAttribute('aria-expanded', state);
  menu.setAttribute('data-open', state);
};

let lastScrollTop = 0;
window.addEventListener('scroll', () => {
  const st = window.pageYOffset;
  st >= 300
    ? (hideHeader(),
      st < lastScrollTop && header.setAttribute('aria-hidden', 'false'),
      (lastScrollTop = st <= 0 ? 0 : st))
    : showHeader();
});

burgerToggle.addEventListener('click', () => {
  const isOpen = burgerToggle.getAttribute('aria-expanded') === 'true';
  toggleMenu(!isOpen);
});
